<template>
	<skeleton>
		<bread-crumb></bread-crumb>
		<div class="loading-back" v-if="$root.isLoading">
			<img
				class="opacity-50 w-6"
				:src="require('@/assets/images/spinner.gif')"
				alt=""
			/>
		</div>
		<router-view></router-view>
	</skeleton>
</template>

<script>
import Skeleton from './components/Skeleton';
import BreadCrumb from '@/components/BreadCrumb';
export default {
	mounted: function() {
		let element = document.body;
		if (this.$root.isInsideIframe) {
			element = document.getElementsByTagName('iframe')[0].contentDocument
				.body;
		}
		element.style.backgroundImage = `url("${require('@/assets/images/login-bg.png')}")`;
	},
	components: { BreadCrumb, Skeleton }
};
</script>

<style></style>
